<!--源代码在线审计页面-->
<template>
  <div class="code-audit-page">
    <md5-header />
    <main class="code-audit-box">
      <div class="code-audit-component">
        <div class="head-bar">
          <a
            href="javascript:history.back(-1)"
            rel="noopener noreferrer"
            class="goback"
            :title="$t('lang.gobackBack')"
          >
            <i class="el-icon-back"></i>
            {{ $t("lang.goback") }}
          </a>
          <h3 class="title">{{ h3 }}</h3>
        </div>
        <div class="audit-step-box">
          <!-- 状态 wait / process / finish / error / success -->
          <el-steps
            :active="1"
            finish-status="finish"
            :process-status="StepProcessStatus"
            align-center
          >
            <el-step :title="$t('lang.websocket.uploadFile')"></el-step>
            <el-step :title="$t('lang.websocket.auditStatus')"></el-step>
            <el-step :title="$t('lang.websocket.download')"></el-step>
          </el-steps>
        </div>

        <!-- 审计操作区域 -->
        <div class="action-audit-box">
          <el-card shadow="always">
            <!-- 上传文件 -->
            <div class="upload-file-step" v-show="auditStep == 0">
              <el-upload
                drag
                action="/api/v1/online_tools/audit_upload"
                multiple
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :show-file-list="false"
              >
                <img src="../../assets/img/upload.png" class="upload" alt="" />
                <div class="el-upload__text">
                  {{ $t("lang.websocket.uploadText") }}
                  <em>{{ $t("lang.websocket.clickUpload") }}</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  {{ $t("lang.websocket.uploadTip") }}
                </div>
              </el-upload>
              <div class="foot">
                <el-alert
                  :title="$t('lang.websocket.uploadFoot')"
                  type="info"
                  show-icon
                  center
                  class="foot-info"
                ></el-alert>
              </div>
            </div>
            <!-- 审计状态 -->
            <div class="audit-status-step" v-show="auditStep == 1">
              <el-result
                :title="Tiptitle"
                :subTitle="'当前审计的文件：' + codeAudit_file_name"
              >
                <template slot="icon">
                  <div class="state-img">
                    <img
                      src="@/assets/img/auditLoading.png"
                      :alt="$t('lang.websocket.audit')"
                      v-if="auditState == 0"
                    />
                    <img
                      src="@/assets/img/audit-success.png"
                      :alt="$t('lang.websocket.auditDone')"
                      v-else-if="auditState == 1"
                    />
                    <img
                      src="@/assets/img/audit-error.png"
                      :alt="$t('lang.websocket.auditFail')"
                      v-else
                    />
                  </div>
                </template>
                <template slot="extra">
                  <div class="countSecond" v-show="countSecond">
                    {{ $t("lang.websocket.underAudits") }}（{{
                      countSecond
                    }}秒）
                  </div>
                  <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-search"
                    @click="CheckState"
                    style="margin-right: 10px"
                    >{{ $t("lang.websocket.checkState") }}</el-button
                  >
                  <el-popconfirm
                    :title="$t('lang.websocket.checkFailState')"
                    @confirm="goBack"
                  >
                    <el-button type="info" size="medium" slot="reference">{{
                      $t("lang.websocket.goBackUpload")
                    }}</el-button>
                  </el-popconfirm>
                </template>
              </el-result>
            </div>
            <!-- 审计报告 下载 -->
            <div class="audit-status-step" v-show="auditStep == 2">
              <el-result :title="Tiptitle" subTitle>
                <template slot="icon">
                  <div class="state-img">
                    <img
                      src="@/assets/img/audit-error.png"
                      :alt="$t('lang.websocket.auditError')"
                      v-if="auditState == 1"
                    />
                    <img
                      src="@/assets/img/audit-success.png"
                      :alt="$t('lang.websocket.auditSuccess')"
                      v-else
                    />
                  </div>
                </template>
                <template slot="extra">
                  <el-button
                    type="primary"
                    size="medium"
                    icon="el-icon-download"
                    @click="downloadAuditReport"
                    style="margin-right: 10px"
                    v-show="auditState == 1"
                    >{{ $t("lang.websocket.downloadAudit") }}</el-button
                  >
                  <el-popconfirm
                    :title="$t('lang.websocket.goBackUploadFile')"
                    @confirm="goBack"
                  >
                    <el-button type="info" size="medium" slot="reference">{{
                      $t("lang.websocket.reUpload")
                    }}</el-button>
                  </el-popconfirm>
                </template>
              </el-result>
            </div>
          </el-card>
        </div>
      </div>
    </main>
    <md5-footer />
  </div>
</template>

<script>
import md5Header from "./../../components/Header";
import md5Footer from "./../../components/Footer";
import { mapState } from "vuex";
import { codeAuditCheckState } from "@/api/codeAudit";
import { get_tools_detail } from "@/api/tools";

export default {
  name: "code-audit",
  components: {
    md5Header,
    md5Footer,
  },
  data() {
    return {
      auditStep: 1, //步骤
      StepProcessStatus: "", //success  error
      Tiptitle: "",
      timer: null,
      auditState: 0, //审计状态
      timerSecond: null,
      countSecond: 0,
      h3: "",
    };
  },
  computed: {
    ...mapState([
      "codeAudit_md5_key",
      "codeAudit_file_name",
      "codeAudit_download_md5_key",
      "serverMessage",
      "serverCode",
    ]),
  },
  methods: {
    getToolsDetail() {
      get_tools_detail({ id: this.$route.query.id }).then((res) => {
        if (res) {
          this.h3 = res.name;
        }
      });
    },
    //下载审计报告
    downloadAuditReport() {
      let md5Key = this.codeAudit_download_md5_key;

      this.$store.commit("set_codeAudit_md5_key", null);
      this.$store.commit("set_codeAudit_file_name", null);
      this.$store.commit("set_codeAudit_download_md5_key", null);

      this.auditStep = 0;
      this.auditState = 0;
      this.StepProcessStatus = "";
      //下载
      window.location.href =
        "/api/v1/online_tools/result_download?md5=" + md5Key;
    },
    //返回
    goBack() {
      this.auditStep = 0;
      this.StepProcessStatus = ""; //还原 步骤 状态
      this.clearTimer_init();
      //清空上传文件的参数key:文件名 ,md5key ，下载key
      this.$store.commit("set_codeAudit_md5_key", null);
      this.$store.commit("set_codeAudit_file_name", null);
      this.$store.commit("set_codeAudit_download_md5_key", null);
    },
    //审计状态
    CheckState() {
      let postObj = {
        md5: this.codeAudit_md5_key,
      };
      //请求状态
      codeAuditCheckState(postObj)
        .then((res) => {
          if (res) {
            switch (res.status) {
              case 0: //进行中
                this.auditState = 0;
                this.$message.warning("正在审计中...");
                this.StepProcessStatus = "";
                break;
              case 1: //审计完成
                this.auditState = 1; //显示成功审计图片
                this.auditStep = 2; //完成
                this.$store.commit(
                  "set_codeAudit_download_md5_key",
                  res.download_md5
                ); //保存key

                this.StepProcessStatus = "success";
                this.Tiptitle = this.serverMessage;
                //提示成功
                this.$notify({
                  message: `${this.codeAudit_file_name}审计已完成`,
                  type: "success",
                });
                break;
              default:
                //审计完成没有错误
                //完成
                //"审计失败！";
                this.auditState = -1;
                this.auditStep = 2;
                this.Tiptitle = this.serverMessage;
                this.StepProcessStatus = "success";
                //提示成功
                this.$message.success(this.serverMessage);
            }

            //获取到结果 就停掉 自动轮询
            if (res.data.status !== 0) {
              this.clearTimer_init(); //初始化计时器
            }
          } else {
            //错误的情况下,
            this.clearTimer_init(); //初始化计时器
            this.auditState = -1;
            this.Tiptitle = res.message; //"审计失败！";
          }
        })
        .catch(() => {
          this.clearTimer_init(); //初始化计时器
        });
    },
    //初始化计时器
    clearTimer_init() {
      clearInterval(this.timer); //销毁自动轮询 查询状态
      clearInterval(this.timerSecond); //计时数器秒
      this.timer = null;
      this.timerSecond = null;
      this.countSecond = 0;
    },
    //上传成功
    uploadSuccess(res, file) {
      if (res.code == 200) {
        //上传成功
        this.$message({
          message: res.message,
          type: "success",
        });
        this.auditState = 0; //审计中
        this.auditStep = 1;
        this.Tiptitle = "";
        this.$store.commit("set_codeAudit_md5_key", res.data.md5); //保存上传成功的md5值
        this.$store.commit("set_codeAudit_file_name", file.name); //保存文件名到vuex

        this.StepProcessStatus = "success";
        //文件上传成功，开始轮询查询 审计状态
        this.timer = null;
        this.timer = setInterval(() => {
          this.CheckState(); //10秒轮询一次
        }, 10000);

        this.timerSecond = null;
        this.countSecond = 0;
        this.timerSecond = setInterval(() => {
          ++this.countSecond;
        }, 1000);
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
        this.StepProcessStatus = "error";
      }
    },
    //上传失败
    uploadError(err) {
      this.$message({
        message: "文件上传失败:" + err,
        type: "error",
      });
    },
    //获取是否曾经有上传过文件.zpi,还没获取到结果, 根据 cookies判断
    getCurrentIsUpload() {
      if (this.codeAudit_download_md5_key) {
        this.auditStep = 2;
        this.auditState = 1; //审计完成，图片是发现异常
      } else {
        this.auditStep = 0;
        this.auditState = 0;
        this.clearTimer_init();
      }
    },
  },
  created() {
    this.getToolsDetail();
    this.getCurrentIsUpload();
    /*this.$store.commit('set_codeAudit_md5_key', null);
    this.$store.commit('set_codeAudit_file_name', null);
    this.$store.commit('set_codeAudit_download_md5_key', null);*/
  },
  destroyed() {
    this.clearTimer_init();
  },
};
</script>

<style lang="scss" scoped>
.code-audit-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  .code-audit-box {
    flex: 1;
    padding-top: 30px;
    width: 1208px;
    margin: 0 auto;
    // 最大白色盒子
    .code-audit-component {
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 1px 13px 9px #287ea61a;
      padding: 35px 35px 60px;
      border-radius: 6px;

      //源代码在线审计盒子
      .head-bar {
        position: relative;
        .goback {
          position: absolute;
          right: 0;
          font-size: 16px;
          font-weight: 400;
          color: rgba(68, 169, 254, 1);
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
      // 源代码在线审计文字
      .title {
        width: 100%;
        height: 43px;
        font-size: 30px;
        font-weight: bold;
        color: #333333;
        line-height: 1;
        text-align: center;
      }

      // 进度条
      .audit-step-box {
        margin-top: 15px;
      }
      // 上传文件的盒子
      .action-audit-box {
        width: 1138px;
        min-height: 498px;
        margin: 30px auto 0;
        text-align: center;
        /deep/ .el-card__body {
          padding: 42px 20px;
        }
        /deep/ .el-upload-dragger {
          width: 582px;
          height: 302px;
        }
        // 上传的云朵图标
        .upload {
          width: 92.3px;
          height: 70px;
          margin-top: 100px;
          margin-bottom: 13px;
        }
      }

      .el-upload__text {
        font-weight: 500;
        color: rgba(128, 128, 128, 1);
      }

      // 工具仅支持PHP，Javascript语言的代码
      .foot {
        width: 582px;
        height: 46px;
        border-radius: 6px;
        background: rgba(245, 245, 245, 1);
        display: flex;
        justify-content: flex-start;
        margin-top: 35px;
        color: rgba(166, 166, 166, 1);
        margin: 33px auto;
      }
      .foot-info {
        justify-content: flex-start;
      }
      // X 关闭按钮按钮
      ::v-deep .el-alert.is-light .el-alert__closebtn {
        font: 500 20px/20px "";
      }
      .audit-status-step {
        /deep/ .state-img {
          width: 220px;
          height: 220px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        /deep/ .el-result__title p {
          color: #606266;
          font-weight: bold;
        }

        /deep/ .el-result__extra {
          margin-top: 15px;
          .countSecond {
            margin-bottom: 15px;
            font-size: 14px;
            color: #606266;
          }
        }
      }
    }
  }
  // 请将代码压缩为ZIP格式再上传，文件大小不超过2Mb
  .el-upload__tip {
    color: rgba(166, 166, 166, 1);
  }
  // 123
  ::v-deep .is-text {
    width: 40px;
    height: 40px;
    background: rgba(245, 245, 245, 1);
    font-size: 18px;
    border: 0;
  }
  // 给123前面添加0
  ::v-deep .el-step__icon-inner::before {
    content: "0";
  }

  // 上传文件 审计状态 下载报告
  ::v-deep .el-step__title {
    font-size: 14px;
  }
  // 横线
  ::v-deep .el-step__line {
    top: 17px;
    background: rgba(245, 245, 245, 1);
  }
  // 上传文件最外层盒子的盒子阴影
  ::v-deep .is-always-shadow {
    box-shadow: 0px 0px 10px 0px rgba(11, 102, 176, 0.1);
  }
}
</style>
