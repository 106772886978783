/**
 * @description 代码审计 APi接口
 * @date 2022-04-23 21:28 星期六
 * 
 */
import request from '@/utils/request';

//代码审计工具检查状态
export function codeAuditCheckState(query) {
  return request({
    url: '/online_tools/check_state',
    method: 'get',
    params: query
  })
}

